import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DxButtonTypes } from 'devextreme-angular/ui/button';
import { ShohayBlogService } from 'src/app/shared/services/shohay-blog/shohay-blog.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
})
export class GlobalSearchComponent implements OnInit {
  searchValue: string = '';
  resultType: string = 'Article/News';
  public searchResults: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly utilitiesService: UtilitiesService,
    private readonly shohayBlogService: ShohayBlogService
  ) {}
  
  ngOnInit() {
    this.utilitiesService.showSpinner(true);
    this.shohayBlogService.getArticleNews().subscribe({
      next: (result) => {
        this.utilitiesService.showSpinner(false);
        this.searchResults = result;
      },
      error: (error: any) => {
        this.utilitiesService.showSpinner(false);
      },
    });
  }

  get filteredResults(): any[] {
    return this.searchResults.filter(
      (item) =>
        item.title.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.topic.toLowerCase().includes(this.searchValue.toLowerCase())
    );
  }

  goToNgoPage(ngoName: string): void {
    const queryParams = { name: JSON.stringify({ name: ngoName }) };
    this.router.navigate(['/ngo'], { queryParams });
  }

  public goToArticleNews(id: number, newsArticleId: string): void {
    this.router.navigate(['/news-article'], {
      queryParams: { newsArticleId: newsArticleId, id: id },
    });
  }

  public searchIcon: DxButtonTypes.Properties = {
    icon: './assets/images/icon/find_icon.svg',
    stylingMode: 'text',
  };
}
