import { Component, OnInit } from '@angular/core';
import { CallBookingModel } from 'src/app/shared/model/call-booking.model';
import {
  ContactTypeEnum,
  NgoStatusEnum,
} from 'src/app/shared/enum/call-booking.enum';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { ContactRequestService } from 'src/app/shared/services/contact-request/contact-request.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-hp9-contact-form',
  templateUrl: './hp9-contact-form.component.html',
  styleUrls: ['./hp9-contact-form.component.scss'],
})
export class Hp9ContactFormComponent implements OnInit {
  public today: Date = new Date();
  public contactType = ContactTypeEnum;
  public ngoStatus = NgoStatusEnum;
  public contactForm: CallBookingModel = new CallBookingModel();

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly contactRequestService: ContactRequestService
  ) {}

  ngOnInit(): void {}

  public changeContactType(contactType: ContactTypeEnum): void {
    this.contactForm.contact_type = contactType;
  }

  public submit(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.utilitiesService.showSpinner(true);
      this.contactRequestService.bookACall(this.contactForm).subscribe({
        next: (result: any) => {
          this.utilitiesService.showSpinner(false);
          this.utilitiesService.showSwalWithToast(
            'Success',
            `${
              this.contactForm.contact_type === this.contactType.CALL
                ? 'Your call is booked'
                : 'Message send'
            } successfully.`
          );
          ValidationEngine.resetGroup('validationGrp');
        },
        error: (error: any) => {
          this.utilitiesService.showSpinner(false);
          this.utilitiesService.showSwalWithToast(
            error.error.error_name,
            error.error.message,
            'error'
          );
        },
      });
    }
  }
}
