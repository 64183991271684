<div class="root">
  <div class="news-container">
    <h1 class="stay-updated-with">
      {{ "Article-News.stay-updated" | translate }}
    </h1>
  </div>
  <div class="news-container">
    <div class="buttons">
      <button
        #articleButton
        class="blue-button-rounded-md"
        style="padding-inline: 32px"
        (click)="
          toggleArticleNews(
            newsArticleTypeEnum.ARTICLE,
            'blue-button-rounded-md',
            'white-button-rounded-md'
          )
        "
      >
        {{ "Article-News.Articles" | translate }}
      </button>
      <button
        #newsButton
        class="white-button-rounded-md"
        style="padding-inline: 32px"
        (click)="
          toggleArticleNews(
            newsArticleTypeEnum.NEWS,
            'white-button-rounded-md',
            'blue-button-rounded-md'
          )
        "
      >
        {{ "Article-News.News" | translate }}
      </button>
    </div>
  </div>
  <section class="news-cards">
    <div *ngFor="let article of articleNewsList" class="cards">
      <div
        class="rectangle-parent"
        [style]="'background-image: url(' + article?.cover_photo + ');'"
      >
        <div class="article-wrapper">
          <div class="article">
            {{
              article?.blog_type === newsArticleTypeEnum.ARTICLE
                ? "Article"
                : "News"
            }}
          </div>
        </div>
      </div>
      <div class="primary-education-bangladesh">
        {{ article?.topic }}
      </div>
      <div class="development-ngos-arm">
        {{ article?.title }}
      </div>
      <div
        class="frame-group"
        (click)="goToArticleNews(article?.id, article?.news_article_id)"
      >
        {{ "Article-News.Read-more" | translate }}
        <img
          class="arrow-right-alt-icon"
          loading="lazy"
          alt=""
          src="../../../../../assets/images/icon/arrow-right-alt.svg"
        />
      </div>
    </div>
  </section>
  <div class="news-footer">
    <button class="blue-button-md" [routerLink]="'search-blog'">
      {{ "Article-News.See-all" | translate }}
    </button>
  </div>
</div>
