import { Component, Input } from '@angular/core';
import { ICampaign } from 'src/app/shared/interface/campaign/campaign.interface';
import { CampaignsService } from 'src/app/shared/services/campaign/campaigns.service';
import { NgoCampaignService } from 'src/app/shared/services/ngo-campaign/ngo-campaign.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donation-buttons',
  templateUrl: './donation-buttons.component.html',
  styleUrl: './donation-buttons.component.scss',
})
export class DonationButtonsComponent {
  public featuredCampaigns: any[] = [];
  private activeIndex = 0;
  public noOfSlides: any[];

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly campaignService: NgoCampaignService
  ) {}

  ngOnInit(): void {
    this.campaignService.getFeaturedNgoDonationCampaign().subscribe({
      next: (result) => {
        this.featuredCampaigns = result;
        this.noOfSlides = Array(result?.length - 2).fill(0);
      },
      error: (err) => {
        this.utilitiesService.showSpinner(false);
      },
    });
  }
}