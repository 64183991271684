<div class="body">
  <div class="search-result-for">
    Search result for "
    <span> {{ searchValue + '"' }}</span>
  </div>
  <div class="w-100 d-flex">
    <dx-text-box
      class="search_box"
      [(ngModel)]="searchValue"
      placeholder="Search articles or news"
      [inputAttr]="{ 'aria-label': 'text' }"
      stylingMode="filled"
      [showClearButton]="true"
      [spellcheck]="true"
    >
      <!-- <dxi-button
        name="searchIcon"
        [options]="searchIcon"
        location="before"
      ></dxi-button> -->
    </dx-text-box>
  </div>
  <div class="search-result-row">
    <span class="search-text">{{ filteredResults.length }}</span>
    results found
  </div>
  <div class="last-search-result-row">
    <div class="article-news">
      <div class="d-flex">
        {{ resultType }}
      </div>
      <div>
        <div *ngFor="let item of filteredResults">
          <div
            class="list-item"
            (click)="goToArticleNews(item.id, item.news_article_id)"
          >
            <span class="list-item-first-line">{{ item?.title }}</span>
            <span style="line-height: 24px">{{ item?.topic }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
