<div class="window">
  <div class="home_page">
    <app-donation-buttons
      style="background-color: antiquewhite"
    ></app-donation-buttons>
    <app-hp1-hero></app-hp1-hero>
    <app-hp2-new-discover-shohay></app-hp2-new-discover-shohay>
    <app-hp-campaign></app-hp-campaign>
    <app-hp2-article-news></app-hp2-article-news>
    <app-hp3-service></app-hp3-service>
    <app-hp4-sign-up></app-hp4-sign-up>
    <app-hp5-ngo-tools></app-hp5-ngo-tools>
    <app-hp6-usp></app-hp6-usp>
    <app-hp7-partners></app-hp7-partners>
    <app-hp8-testimonials></app-hp8-testimonials>
    <app-hp9-contact-form></app-hp9-contact-form>
  </div>
</div>
